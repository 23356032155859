import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import config from "app/api/configs/config.json";
import { BrowserRouter } from "react-router-dom";
import { isProd } from "app/helpers/isProd";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { isMobile } from "react-device-detect";
import "index.scss";
import Loader, { LoaderVariants } from "components/loader";
import { RecoilRoot } from "recoil";
import * as serviceWorker from "serviceWorkerRegistration";

if (isProd) {
  Sentry.init({
    dsn: "https://dd50322091a640b980b5683b880904f1@o442442.ingest.sentry.io/5915836",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const firebaseApp = firebase.initializeApp(config);

export const auth = getAuth(firebaseApp);
export { firebase };

const AppComponent = isMobile
  ? lazy(() => import("AppMobile"))
  : lazy(() => import("./pages/sorryGoToMobile"));

export const rootDiv = document.getElementById("root");

// const auth0Props = getAuthConfig(currentEnvironment);

ReactDOM.render(
  <React.StrictMode>
    {/* <Auth0Provider */}
    {/*  {...auth0Props} */}
    {/*  redirectUri={window?.location.origin} */}
    {/*  useRefreshTokens={false} */}
    {/*  audience={"https://auth0-jwt-authorizer"} */}
    {/*  scope={"email read:appointments profile"} */}
    {/*  cacheLocation={"localstorage"} */}
    {/* > */}
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<Loader variant={LoaderVariants.big} />}>
          <AppComponent />
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
    {/* </Auth0Provider> */}
  </React.StrictMode>,
  rootDiv
);

serviceWorker.unregister();

if (caches) {
  caches.keys().then((keys) => keys.forEach((key) => caches.delete(key)));
}
