import React from "react";
import { BeatLoader } from "react-spinners";
import s from "./styles.module.scss";
import colors from "uikit/colors.module.scss";

export enum LoaderVariants {
  big = "big",
  small = "small",
}

const loaderSizes = {
  [LoaderVariants.big]: 30,
  [LoaderVariants.small]: 10,
};

const loaderClasses = {
  [LoaderVariants.big]: s.loaderBigWrapper,
  [LoaderVariants.small]: s.loaderSmallWrapper,
};

const Loader: React.FC<{
  variant?: LoaderVariants;
  className?: string;
  mt?: number;
}> = ({ variant = LoaderVariants.small, className, mt }) => (
  <div
    className={`${loaderClasses[variant]} ${className || ""}`}
    style={{
      marginTop: mt,
    }}
  >
    <BeatLoader size={loaderSizes[variant]} color={colors.accentBlue} />
  </div>
);

export default Loader;
